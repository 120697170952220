
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { SituacaoUnidadeService } from "@/core/services/cadastros";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgEmpreendimento extends Vue {
  filter: {
    empreendimentoId: [];
    situacaoId: [];
    empresaId: [];
  } = {
    empreendimentoId: [],
    situacaoId: [],
    empresaId: [],
  };

  
   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório de Unidades', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  get selecionarTodosEmpreendimentos() {
    return this.filter.empreendimentoId.length === this.empreendimentos.length;
  }

  get SelecionarAlgunsEmpreendimentos() {
    return (
      this.filter.empreendimentoId.length > 0 &&
      !this.selecionarTodosEmpreendimentos
    );
  }

  get iconEmpreendimento() {
    if (this.selecionarTodosEmpreendimentos) return "mdi-close-box";
    if (this.SelecionarAlgunsEmpreendimentos) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  get textEmpreendimento() {
    if (this.selecionarTodosEmpreendimentos) return "Desmarcar";
    if (this.SelecionarAlgunsEmpreendimentos) return "Selecionando...";
    return "Selecionar todos";
  }

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
      if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentoId = [];
      } else {
        this.filter.empreendimentoId = this.empreendimentos
          .slice()
          .map((x: any) => x.id);
      }
    });
  }

  get selecionarTodosSituacao() {
    return this.filter.situacaoId.length === this.listaSituacoes.length;
  }

  get SelecionarAlgunsSituacao() {
    return (
      this.filter.situacaoId.length > 0 && !this.selecionarTodosSituacao
    );
  }

  get iconSituacao() {
    if (this.selecionarTodosSituacao) return "mdi-close-box";
    if (this.SelecionarAlgunsSituacao) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  get textSituacao() {
    if (this.selecionarTodosSituacao) return "Desmarcar";
    if (this.SelecionarAlgunsSituacao) return "Selecionando...";
    return "Selecionar todos";
  }

  SelecionarSituacao() {
    this.$nextTick(() => {
      if (this.selecionarTodosSituacao) {
        this.filter.situacaoId = [];
      } else {
        this.filter.situacaoId = this.listaSituacoes
          .slice()
          .map((x: any) => x.id);
      }
    });
  }

  get selecionarTodosEmpresa() {
    return this.filter.empresaId.length === this.empresas.length;
  }

  get SelecionarAlgunsEmpresa() {
    return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresa;
  }

  get iconEmpresa() {
    if (this.selecionarTodosEmpresa) return "mdi-close-box";
    if (this.SelecionarAlgunsEmpresa) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  get textEmpresa() {
    if (this.selecionarTodosEmpresa) return "Desmarcar";
    if (this.SelecionarAlgunsEmpresa) return "Selecionando...";
    return "Selecionar todos";
  }

  SelecionarEmpresa() {
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresa) {
        this.filter.empresaId = [];
      } else {
        this.filter.empresaId = this.empresas.slice().map((x: any) => x.id);
      }
    });
  }

  valid = true;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  empreendimentos: any = [];
  listaSituacoes: any = [];
  empresas: any = [];

  mounted() {
    new EmpreendimentoService().Listar(-1, -1, ["nome"], [], "", [], "", "", "Id, nome", "")
      .then((res) => {
        this.empreendimentos = res.data.items;
      });

    new SituacaoUnidadeService().Listar(-1, -1, ["nome"], [], "", [], "", "", "Id, nome", "")
      .then((res) => {
        this.listaSituacoes = res.data.items;
      });

    new EmpresaService().Listar(-1, -1, ["nome"], [], "", [], "", "", "Id, nome", "")
      .then((res) => {
        this.empresas = res.data.items;
      });
  }

  Visualizar() {
    if(this.filter.empreendimentoId.length == 0 && this.filter.situacaoId.length == 0 && this.filter.empresaId.length == 0){
        this.$swal("Aviso", "Obrigatório preencher pelo menos um dos campos.", "warning");
        return true;
    }    
    let routeLink = this.$router.resolve({
      name: "relUnidade",
      query: {
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString(): "",
        situacaoId: this.filter.situacaoId ? this.filter.situacaoId.toString(): "",
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString(): "",
      },
    });

    window.open(routeLink.href, "_blank");
  }
}
